import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';

//Assets
import BgGreen from '../Assets/card-green-bg.png';
import CvGreen from '../Assets/card-green-cover-new.png';
import BlurredAccessPaper from './Components/BlurredAccessPaper';

export default function CorrectionEdicaoEspecial() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [showBlur, setShowBlur] = useState(true);
	const [userEmail, setUserEmail] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const correcaoEmail = localStorage.getItem('email-correcao');
		if (correcaoEmail) {
			setUserEmail(correcaoEmail);
		}
	}, []);

	useEffect(() => {
		const resposta = localStorage.getItem('resposta-correcao');
		if (resposta) {
			setShowBlur(false);
		}
	}, []);

	const executeScroll = () => embeddedContent.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const handleSubscribed = () => {
		setIsSubscribed(true);
	};

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const cidade = query.get('cidade');

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? null : <Header />}
			<Grid
				container
				className="section"
				style={matchesMobile ? { marginTop: 80 } : { marginBottom: 0 }}
			>
				<Grid
					item
					xs={12}
					md={12}
					container
					justifyContent="center"
				>
					{matchesMobile ? (
						<img
							src={require('../Assets/logo-mobile.png')}
							alt=""
						/>
					) : (
						<img
							src={require('../Assets/correcao-banner-new.png')}
							style={{ left: -20 }}
							alt=""
						/>
					)}
				</Grid>

				<Grid
					item
					xs={12}
					container
					alignItems={'center'}
					justifyContent={'center'}
					style={matchesMobile ? { marginBottom: 16 } : { marginTop: '2em' }}
				>
					<Typography
						className={`${classes.subtitle2} poppins`}
						color="secondary"
					>
						Faça login ou inscreva-se abaixo para acompanhar a correção e participar do sorteio!
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					ref={embeddedContent}
				>
					{showBlur && <BlurredAccessPaper handleClose={() => setShowBlur(false)} />}

					<iframe
						title="correcao"
						src={`//hardworkmedicina.com.br/correcao-prova/128?email=${userEmail}`}
						style={{ border: 0, width: '100%', height: '790px', backgroundColor: 'transparent' }}
					></iframe>
					<section style={{ display: 'flex', paddingTop: '3rem', padding: matchesTablet && '1rem' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: matchesTablet && 'column',
								backgroundColor: 'white',
								width: '100%',
								height: '577px',
								borderRadius: '18px',
							}}
						>
							<div
								className={classes.cardBackground}
								style={{
									width: matchesTablet ? '100%' : '50%',
									backgroundColor: '#34A86E',
									height: matchesTablet && '50%',
								}}
							/>
							<div
								className={`poppins`}
								style={{
									backgroundColor: 'white',
									borderRadius: '16px',
									width: matchesTablet ? '100%' : '50%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<div
									style={{
										padding: '20px',
										color: 'black',
										display: 'flex',
										flexDirection: 'column',
										gap: matchesMobile ? '0.9rem' : '2rem',
										fontSize: matchesTablet ? '14px' : '20px',
										fontWeight: '500',
									}}
								>
									<p>É hora de continuar a construir nossa história de aprendizado!</p>

									<p>
										Independente do resultado da primeira fase, precisamos aproveitar tudo que já
										foi aprendido e evoluir, melhorar cada vez mais!
									</p>

									<p>
										Ajustamos o ARENA HARDWORK para que tu possas continuar evoluindo, mesmo sem ter
										o resultado da primeira fase definido.
									</p>

									<p>Uma oportunidade ÚNICA de manter a rotina de estudos e não parar!</p>

									<p>
										Dependendo do resultado da primeira fase, tu poderás escolher continuar no Arena
										ou migrar para o Trilha.
									</p>
								</div>
								<div
									onClick={() => (window.location.pathname = '/arena-hardwork')}
									style={{
										display: 'flex',
										justifyContent: 'center',
										paddingTop: matchesTablet ? '0px' : '8%',
										paddingBottom: matchesMobile && '15px',
									}}
								>
									<div
										style={{
											backgroundColor: '#1C9766',
											padding: '12px 18px',
											borderRadius: '36px',
											cursor: 'pointer',
										}}
									>
										<p
											style={{
												color: 'white',
												fontWeight: 600,
												fontSize: matchesMobile ? '16px' : '20px',
											}}
										>
											Quero continuar estudando
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Grid>
			</Grid>

			<Footer />
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 462,
		[theme.breakpoints.down('lg')]: {
			width: '200 !important',
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.2em',
		},
	},
	cardBackground: {
		backgroundImage: `url(${CvGreen})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		padding: '4rem',
		// height: '400px',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		alignItems: 'center',
		width: '100%',
		borderEndStartRadius: '16px',
		borderStartStartRadius: '16px',
		[theme.breakpoints.down('md')]: {
			borderEndStartRadius: 'unset !important',
			borderStartEndRadius: '16px ',
		},
	},
	cardCover: {
		backgroundImage: `url(${CvGreen})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		padding: '2rem',
		height: '160px',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderStartStartRadius: '16px',
		borderStartEndRadius: '16px',
	},
	divhover: {
		transition: 'ease 0.2s',
		'&:hover': {
			backgroundColor: '#ADFFD6 !important',
			transform: 'scaleY(1.1)',
			border: '2px solid #34A86E',
		},
	},
	firstSectionGridCard: {
		position: 'absolute',
		right: 0,
		top: 20,
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			margin: '16px 0px !important',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	position: 'unset',
		// 	margin: '16px 0px !important',
		// },
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			marginTop: '4rem !important',
		},
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			marginTop: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	impactText: {
		marginTop: '16px !important',
		fontSize: '1.5rem !important',
		lineHeight: 'unset',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		width: 400,
		backgroundColor: '#FFFFFF !important',
		fontSize: '1.5rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
			fontSize: '1rem !important',
		},
	},
	backgroundImg: {
		backgroundImage: `url(${BgGreen})`,
	},
}));
