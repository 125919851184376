import { useState } from 'react';
// import { useRef } from 'react';
import { Button, Container, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../../../../../Components/Buttons/DefaultButton';

import BgTechGrey from '../../../../../Assets/SprintFinal/bg-tech-grey.png';
import PurchaseCardOnline20242 from './PurchaseCardOnline2024';

import Api from '../../../../../Services/Api';
import Swal from 'sweetalert2';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import bgTitleArena from '../assets/bg-arena-title.svg';
import imgInfo1 from '../assets/img-arena-info-1.png';
import imgInfo2 from '../assets/img-arena-info-2.png';
import imgInfo3 from '../assets/img-arena-info-3.png';
import imgBlurred from '../assets/img-blurred.png';

const arenaOnlineBenefits = [
	'+200  checklists com orientação dos professores',
	'+200  estações com orientação dos professores',
	'+150  casos temáticos',
	'+30  estações em vídeos',
	'+20  vídeos de procedimentos',
	'Pratique estações com outros alunos',
	'Pratique estações com a Inteligência Artificial',
	'BÔNUS: Pratique individualmente estações com instrutores Hardwork',
	'Prioridade de inscrição no Arena Presencial',
];

const checklists = [
	{
		icon: require('../assets/icon-green-doctor-arena.svg').default,
		text: 'O maior número de checklists da história',
	},
	{
		icon: require('../assets/icon-green-lamp-arena.svg').default,
		text: 'O maior número de estações da história',
	},
	{
		icon: require('../assets/icon-green-sheets-arena.svg').default,
		text: 'A maior preparação para prova prática do Revalida INEP',
	},
];

export function AviseMeModal({ agroupment }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `${agroupment}`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const handleCloseWaitList = () => {
		setIsOpenModal(false);
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	let sendPreInscricao = async function () {
		try {
			if (!preInscricao.nome) {
				alert('Ops... Por favor informe seu nome');
				return;
			}
			if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
				alert('Ops... Por favor preencha seu email');
				return;
			}
			if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
				alert('Ops... Por favor preencha seu telefone');
				return;
			}

			await Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then(() => {
						setIsOpenModal(false);
					});
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div
			style={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: 130,
				backgroundColor: '#FFFFFF26',
				borderRadius: 24,
			}}
		>
			{/* {agroupment === 'ARENA ONLINE - 2024.2 - LISTA ESPERA' && ( */}
			<p style={{ color: '#FFFFFF ', fontFamily: 'Poppins', fontSize: 26 }}>
				Abertura em {agroupment === 'ARENA ONLINE - 2024.2 - LISTA ESPERA' ? '17/09' : '26/09'}
			</p>
			{/* )} */}
			<DefaultButton
				onClick={() => setIsOpenModal(true)}
				variant="contained"
				color={'primary'}
				className={`${classes.selectButton} poppins`}
				style={{
					height: 47,
					width: '60%',
					backgroundColor: '#00AD58',
				}}
			>
				Avise-me
			</DefaultButton>
			{isOpenModal && (
				<form
					className="form-control"
				>
					<Grid
						item
						container
						justifyContent={'center'}
						className={classes.containerWaitList}
						rowGap={1}
						style={{ visibility: isOpenModal ? 'visible' : 'hidden' }}
					>
						<Grid
							item
							container
							justifyContent="space-between"
						>
							<Typography
								color="primary"
								className={`${classes.textWaitList} poppins`}
							>
								Inscrever-se na Lista de Espera
							</Typography>
							<CancelRoundedIcon
								onClick={() => handleCloseWaitList()}
								className={classes.cancelIcon}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
						>
							<TextField
								fullWidth
								type="tel"
								name="telefone"
								placeholder="Seu celular com DDD"
								required={true}
								pattern="[0-9]*"
								inputProps={{
									maxLength: 15,
								}}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) => handlePhone(e)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							lg={8}
							container
							className="residencia"
							style={{ height: 50, padding: '4px 0px' }}
						>
							<DefaultButton
								// type="submit"
								variant="outlined"
								color="secondary"
								className={`${classes.btnWaitList} btn`}
								onClick={() => sendPreInscricao()}
							>
								<img
									src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
									alt=""
									style={{ width: 20 }}
								/>{' '}
								&nbsp; Confirmar inscrição
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			)}
		</div>
	);
}

export default function ToggleContainerPro({ arena_card, executeScroll, targetDate }) {
	const theme = useTheme();
	const classes = useStyles();

	const now =  new Date()

	const [isContainerOpen, setIsContainerOpen] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState('1374');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleButtonClick = (buttonId) => {
		setActiveButtonId(buttonId);
		setIsContainerOpen(true);
		executeScroll();

	};



	return (
		<>
			<Grid
				container
				className={`${!isContainerOpen ? classes.containerBackground : null}`}
			>
				<Grid
					container
					className="section"
					style={{ margin: '0 auto' }}
				>
					<Grid
						item
						container
						className={`${classes.mainContainer} ${isContainerOpen ? classes.containerBackground : null}`}
						gap={matchesMobile ? 1 : 4}
					>
						{checklists.map((elm, index) => {
							return (
								<Grid
									key={index}
									item
									container
									justifyContent={'space-between'}
									alignItems={'center'}
									wrap="nowrap"
									style={{
										border: '2px solid #09D7D7',
										padding: '0.5rem',
										borderRadius: 20,
										marginBottom:
											index === checklists.length - 1 ? (matchesTablet ? '3rem' : '5rem') : null,
										marginLeft: matchesTablet ? '0.5rem' : null,
									}}
								>
									<Grid
										item
										container
										alignItems={'center'}
										wrap="nowrap"
									>
										<img
											src={elm.icon}
											alt=""
											className={classes.icon}
										/>
										<Typography
											color="secondary"
											className={`${classes.textChecklist} poppins`}
										>
											{elm.text}
										</Typography>
									</Grid>
									<img
										src={require('../assets/check-animation.gif')}
										alt=""
										className={classes.checkGif}
									/>
								</Grid>
							);
						})}
						<Grid
							item
							container
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							<Typography
								align={matchesMobile ? 'center' : 'left'}
								color="#09D785"
								className={`${classes.optionTitle} roboto-condensed`}
							>
								QUAL O MODELO DE ARENA É IDEAL PRA TI?
							</Typography>
						</Grid>
						<Grid
							item
							container
							alignContent={'flex-start'}
							justifyContent={'center'}
							columnGap={10}
							rowGap={matchesTablet ? 3 : 0}
						>
							<Grid
								item
								xs={12}
								lg={5}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								<Button
									variant="contained"
									color={activeButtonId === 1 ? 'primary' : 'secondary'}
									className={`${classes.btn} 
                                    ${activeButtonId === 1 ? null : null} 
                                    poppins 
                                    
                                    `}
									onClick={() => handleButtonClick('1374')}
									style={{
										flexDirection: 'column',
										backgroundColor: '#FFFFFF10',
										scale: activeButtonId === '1374' ? '1.025' : null,
										border: activeButtonId === '1374' ? '1px solid #ffffff' : null,
									}}
								>
									<Grid
										item
										container
										alignContent={'center'}
										alignItems={'center'}
										justifyContent={'center'}
										style={{
											// borderBottom: '1px solid #FFFFFF50',
											padding: matchesTablet ? '1rem' : '1rem 0',
											height: '100%',
										}}
									>
										<Typography
											color="secondary"
											className={`${classes.titleBenefits} poppins`}
											align="center"
										>
											Arena Online
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={'auto'}
										paddingX={4}
										justifyContent="center"
										alignContent={'center'}
										border={'1px solid #09D785'}
										bgcolor={'#464646'}
										borderRadius={2}
										style={{ boxShadow: '0px 0px 12px 0px #09D785' }}
									>
										<Typography
											align="center"
											color={'secondary'}
											className="poppins"
											fontSize={21}
										>
											Acesso EXCLUSIVAMENTE Online
										</Typography>
									</Grid>
									{arenaOnlineBenefits.map((text, index) => {
										const greenText = text.match(/[+\d]+/g);
										const rest = text.replace(/[+\d]+/g, '').trim();
										return (
											<Grid
												key={index}
												item
												container
												justifyContent="center"
												alignContent={'center'}
												alignItems={'center'}
												columnGap={1}
												style={{
													borderBottom:
														index !== arenaOnlineBenefits.length - 1 &&
														'1px solid #FFFFFF50',
													padding: '1rem 0',
													marginTop: index === 0 ? '2rem' : '0',
												}}
											>
												{greenText && (
													<Typography
														align="center"
														color={'#09D785'}
														fontSize={32}
														className={`${classes.textBenefitsGreen} poppins`}
													>
														{`${greenText} `}
													</Typography>
												)}
												<Typography
													color={
														index === arenaOnlineBenefits.length - 1
															? '#09D785'
															: 'secondary'
													}
													className={`${classes.textBenefits} poppins`}
												>
													{rest}
												</Typography>
											</Grid>
										);
									})}
									<Grid
										item
										container
										alignItems={'end'}
										justifyContent={'center'}
										className={classes.gridSelectButton}
									>
										{
											now > targetDate ?
												<AviseMeModal agroupment={'ARENA ONLINE - 2024.2 - LISTA ESPERA'} />
												:
												<DefaultButton
													variant="contained"
													color={activeButtonId === '1374' ? 'secondary' : 'primary'}
													className={`${classes.selectButton} poppins`}
													style={{
														height: 47,
														width: '60%',
														backgroundColor: activeButtonId === '1374' ? '#ffffff' : '#00AD58',
													}}
												>
													{activeButtonId === '1374' ? 'Selecionado' : 'Selecionar'}
												</DefaultButton>
										}
									</Grid>
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								lg={5}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								<Button
									disableElevation
									disableRipple
									variant="contained"
									color={activeButtonId === 1 ? 'primary' : 'secondary'}
									className={`${classes.btn} 
                                    ${activeButtonId === 1 ? null : null} 
                                    poppins 
                                    
                                    `}
									// onClick={() => handleButtonClick('1335')}
									onClick={() => {}}
									style={{
										justifyContent: 'flex-start',
										flexDirection: 'column',
										backgroundColor: '#FFFFFF15',
										backgroundImage:
											'linear-gradient(to bottom right, #ffffff15, #ffffff25, #ffffff05)',
										scale: activeButtonId === '1335' ? '1.025' : null,
										border: activeButtonId === '1335' ? '1px solid #ffffff' : null,
									}}
								>
									<Grid
										item
										container
										alignContent={'center'}
										alignItems={'center'}
										justifyContent={'center'}
										style={{
											opacity: 0.5,
											padding: matchesTablet ? '1rem' : '1rem 0',
										}}
									>
										<Typography
											color="secondary"
											align="left"
											className={`${classes.titleBenefits} poppins`}
										>
											Arena Presencial
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={'auto'}
										paddingX={2}
										justifyContent="center"
										alignContent={'center'}
										border={'1px solid #09D785'}
										bgcolor={'#464646'}
										borderRadius={2}
										style={{ opacity: 0.5 }}
									>
										<Typography
											align="center"
											color={'secondary'}
											className="poppins"
											fontSize={21}
										>
											Acesso EXCLUSIVAMENTE presencial
										</Typography>
									</Grid>
									<div
										style={{
											width: '100%',
											height: matchesTablet ? 400 : '100%',
											backgroundImage: `url(${imgBlurred})`,
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'contain',
											backgroundPosition: matchesTablet ? 'start  ' : 'center',
											alignSelf: 'center',
											marginTop: 60,
										}}
									></div>
									{/* {arenaExperienceBenefits.map((text, index) => {
										return (
											<Grid
												key={index}
												item
												container
												justifyContent="center"
												alignContent={'center'}
												style={{
													opacity: 0.5,
													borderBottom:
														index !== arenaOnlineBenefits.length - 1 &&
														'1px solid #FFFFFF50',
													padding: '1rem 0',
												}}
											>
												<Typography
													align="center"
													color={index === 0 ? '#09D785' : 'secondary'}
													className={`${classes.textBenefits} poppins`}
												>
													{text}
												</Typography>
											</Grid>
										);
									})} */}
									<Grid
										item
										container
										alignItems={'end'}
										justifyContent={'center'}
										className={classes.gridSelectButton}
									>
										<AviseMeModal agroupment={'ARENA PRESENCIAL - 2024.2 - LISTA ESPERA'} />
										{/* <DefaultButton
											variant="contained"
											color={activeButtonId === '1335' ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
											style={{
												height: 47,
												backgroundColor: activeButtonId === '1335' ? '#ffffff' : '#00AD58',
											}}
										>
											{activeButtonId === '1335' ? 'Selecionado' : 'Selecionar'}
										</DefaultButton> */}
									</Grid>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{!activeButtonId && (
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						style={{ padding: matchesTablet && '0 1rem' }}
					>
						<Typography
							color="secondary"
							align="center"
							className="poppins"
							style={{ fontStyle: 'italic', fontSize: matchesTablet ? '0.8rem' : '1.2rem' }}
						>
							Selecione um modelo de Arena para ver mais detalhes...
						</Typography>
					</Grid>
				)}
			</Grid>

			<section
				className="section"
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: matchesTablet ? 'column' : 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginTop: 100,
				}}
			>
				<div style={{ width: '100%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0 30px',
							width: '100%',
							height: 80,
							backgroundImage: `url(${bgTitleArena})`,
							backgroundSize: 'cover',
							border: '2px solid #09D7D7',
							borderRadius: 20,
							boxShadow: '0px 0px 12px 0px #09D7D7',
						}}
					>
						<h1 style={{ fontFamily: 'Poppins', color: '#FFFFFF', fontSize: matchesTablet ? 28 : 49 }}>
							ARENA INTERATIVO
						</h1>
					</div>
					<div style={{ width: '100%', marginTop: 20 }}>
						<p style={{ fontSize: 21, color: '#FFFFFF' }}>
							Pratique estações com a Inteligência Artificial do Hardwork.
						</p>
						<p style={{ fontSize: 21, color: '#FFFFFF', marginTop: 30 }}>
							São mais de 50 estações com casos clínicos e checklists, finalizadas com vídeos de
							professores com orientações da estação.
						</p>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						height: 300,
						backgroundImage: `url(${imgInfo1})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						backgroundPosition: 'right',
					}}
				></div>
			</section>

			<section
				className="section"
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					flexDirection: matchesTablet ? 'column' : 'row',
				}}
			>
				{!matchesTablet && (
					<div
						style={{
							width: '100%',
							height: 300,
							backgroundImage: `url(${imgInfo2})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
						}}
					></div>
				)}

				<div style={{ width: '100%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'end',
							padding: '0 30px',
							width: '100%',
							height: 80,
							backgroundImage: `url(${bgTitleArena})`,
							backgroundSize: 'cover',
							border: '2px solid #09D7D7',
							borderRadius: 20,
							boxShadow: '0px 0px 12px 0px #09D7D7',
						}}
					>
						<h1
							style={{
								fontFamily: 'Poppins',
								color: '#FFFFFF',
								fontSize: matchesTablet ? 28 : 49,
								textWrap: matchesTablet ? 'wrap' : 'nowrap',
							}}
						>
							ESTAÇÃO COM AMIGO
						</h1>
					</div>
					<div style={{ width: '100%', marginTop: 20 }}>
						<p
							style={{
								fontSize: 21,
								color: '#FFFFFF',
								textAlign: matchesTablet ? 'start' : 'end',
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
							}}
						>
							{`Conecte-se com outros alunos através de chamadas\n de vídeo e treine com mais de 200 estações com\n casos clínicos e checklists.`}
						</p>
						<p
							style={{
								fontSize: 21,
								color: '#FFFFFF',
								marginTop: 30,
								textAlign: matchesTablet ? 'start' : 'end',
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
							}}
						>
							{`No final de cada caso, um vídeo explicativo do
                             professor, com orientações da estação.`}
						</p>
					</div>
				</div>

				{matchesTablet && (
					<div
						style={{
							width: '100%',
							height: 300,
							backgroundImage: `url(${imgInfo2})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							marginTop: 30,
						}}
					></div>
				)}
			</section>

			<section
				className="section"
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '40px',
					flexDirection: matchesTablet ? 'column' : 'row',
				}}
			>
				<div style={{ width: '100%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '0 30px',
							width: '100%',
							height: 80,
							backgroundImage: `url(${bgTitleArena})`,
							backgroundSize: 'cover',
							border: '2px solid #09D7D7',
							borderRadius: 20,
							boxShadow: '0px 0px 12px 0px #09D7D7',
						}}
					>
						<h1
							style={{
								fontFamily: 'Poppins',
								color: '#FFFFFF',
								fontSize: matchesTablet ? 28 : 49,
								textWrap: matchesTablet ? 'wrap' : 'nowrap',
							}}
						>
							ESTAÇÃO COM INSTRUTOR
						</h1>
					</div>
					<div style={{ width: '100%', marginTop: 20 }}>
						<p style={{ fontSize: 21, color: '#FFFFFF' }}>Simule a prova com nosso time de instrutores.</p>
						<p style={{ fontSize: 21, color: '#FFFFFF', marginTop: 30 }}>
							Faz toda a diferença ter a vivência real de uma estação, recebendo orientações
							personalizadas de quem entende do assunto.
						</p>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						height: 300,
						backgroundImage: `url(${imgInfo3})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						backgroundPosition: 'right',
					}}
				></div>
			</section>

			<section
				className="section"
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: matchesTablet ? '40px' : '220px',
					flexDirection: matchesTablet ? 'column' : 'row',
				}}
			>
				{!matchesTablet && (
					<div
						style={{
							width: '100%',
							height: 300,
							backgroundImage: `url(${imgInfo2})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
						}}
					></div>
				)}

				<div style={{ width: '100%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'end',
							padding: '0 30px',
							width: '100%',
							height: 80,
							backgroundImage: `url(${bgTitleArena})`,
							backgroundSize: 'cover',
							border: '2px solid #09D7D7',
							borderRadius: 20,
							boxShadow: '0px 0px 12px 0px #09D7D7',
						}}
					>
						<h1
							style={{
								fontFamily: 'Poppins',
								color: '#FFFFFF',
								fontSize: matchesTablet ? 21 : 34,
								textWrap: matchesTablet ? 'wrap' : 'nowrap',
								textTransform: 'uppercase',
							}}
						>
							Live das Lives de Prova prática
						</h1>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
						<p
							style={{
								fontSize: 21,
								color: '#FFFFFF',
								textAlign: matchesTablet ? 'start' : 'end',
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
							}}
						>
							{`Nos 10 dias que antecedem a prova prática, faremos lives diárias, com dicas de preparação para a prova, instrutores contando experiência e dicas de professores Hardwork.`}
						</p>
						{/* <p
							style={{
								fontSize: 21,
								color: '#FFFFFF',
								marginTop: 30,
								textAlign: matchesTablet ? 'start' : 'end',
								whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
							}}
						>
							{`No final de cada caso, um vídeo explicativo do
                             professor, com orientações da estação.`}
						</p> */}
					</div>
				</div>

				{matchesTablet && (
					<div
						style={{
							width: '100%',
							height: 300,
							backgroundImage: `url(${imgInfo2})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							marginTop: 30,
						}}
					></div>
				)}
			</section>

			<Grid container>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Grid
						container
						className="section"
						style={{ marginBottom: matchesMobile ? 20 : null }}
					>
						<Container
							fixed
							maxWidth="xl"
							disableGutters={true}
						>
							{!activeButtonId ? (
								<Grid
									item
									container
									alignContent="center"
									justifyContent="center"
									className={classes.glassContainer}
								>
									<Grid
										item
										container
										justifyContent={'center'}
										style={matchesMobile ? { marginBottom: '1em' } : null}
									>
										<img
											src={require('../../../../../Assets/Arena/logo-lock.svg').default}
											alt=""
										/>
									</Grid>
									<Grid
										item
										container
										alignContent="center"
										justifyContent="center"
									>
										<Typography
											align="center"
											color="secondary"
											className={`${classes.optionTitle} roboto-condensed`}
										>
											Selecione acima de qual Arena tu vais participar para visualizar os valores
											e fazer inscrição.
										</Typography>
									</Grid>
								</Grid>
							) : null}
							<div ref={arena_card}>
								<PurchaseCardOnline20242
									activeButtonId={activeButtonId}
									idTurma={'1374'}
									// urlToRedirect="/arena-hardwork"
									confirmationMessage={
										'Sua inscrição na lista de espera está confirmada! Avisaremos caso surjam novas vagas.'
									}
								/>
							</div>
							{/* <div
								style={{
									display: 'flex',
									flexDirection: matchesTablet ? 'column' : 'row',
									alignItems: 'center',
									justifyContent: 'center',
									gap: 20,
									width: '100%',
									marginTop: 20,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',

										width: matchesTablet ? '100%' : 'fit-content',
										height: 145,
										borderRadius: 16,
										padding: 24,
										background: 'linear-gradient(135deg, #05d687 20%, #088dac 90%)',
									}}
								>
									<h3 style={{ fontSize: matchesTablet ? 18 : 20, color: 'white', fontWeight: 400 }}>
										Quero me inscrever!
									</h3>
									<p
										style={{
											fontSize: matchesTablet ? 18 : 20,
											color: 'white',
											fontWeight: 700,
											whiteSpace: 'pre-wrap',
										}}
									>
										{'Mas e se eu não passar para a\nsegunda fase?'}
									</p>
								</div>
								<div
									style={{
										height: matchesMobile ? '100%' : 145,
										backgroundColor: '#292929',
										borderRadius: 16,
										padding: 24,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
									}}
								>
									<h3
										style={{ color: '#FFFFFF', fontWeight: 400, fontSize: matchesTablet ? 16 : 20 }}
									>
										Calma! Caso não passe para a segunda fase, tu poderás escolher (até o dia 06/10)
										entre:
									</h3>
									<ul
										style={{
											color: '#FFFFFF',
											fontSize: matchesTablet ? 14 : 18,
											marginTop: matchesTablet ? 10 : 0,
										}}
									>
										<li>• Usar o valor investido para migrar para o Trilha Revalida 25.1;</li>
										<li>• Continuar na rotina de estudos do Arena;</li>
										<li>• Desativar a inscrição no Arena sem multa.</li>
									</ul>
								</div>
							</div> */}
						</Container>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		backgroundImage: `url(${BgTechGrey})`,
		width: '100vw !important',
	},
	mainContainer: {
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		padding: '6em 0 2.5em',
		[theme.breakpoints.down('xl')]: {
			padding: '4em 2.5em 6em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em 0 2em 0',
			height: 'unset',
		},
	},
	optionTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
			lineHeight: '1 !important',
		},
	},
	btn: {
		width: '100%',
		height: '100%',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		borderRadius: '15px !important',
		padding: '1.75rem 2rem !important',
		textTransform: 'unset !important',
		transition: '0.2s ease-in-out !important',
		cursor: 'auto !important',
		[theme.breakpoints.up('md')]: {
			'&:hover': {
				scale: 1.025,
			},
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			padding: '1rem 0.5rem 2rem !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '0 auto 30px',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh !important',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0 !important',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '90%',
		margin: '2rem 0 !important',
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 1.5rem ',
		},
	},
	containerFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem ',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 200,
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 180,
		},
	},
	selectButton: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridSelectButton: {
		marginTop: '2rem !important',
		width: '100% !important',
	},
	disclaimerText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	textChecklist: {
		fontSize: '2rem !important',
		left: '-2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			left: '-1.5rem !important',
		},
	},
	checkGif: {
		width: '5rem',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
		},
	},
	icon: {
		left: '-3rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
			left: '-2rem !important',
		},
	},
	textBenefits: {
		fontSize: '18px !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textBenefitsGreen: {
		fontSize: '28px !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	titleBenefits: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	containerGridArenaInterativo: {
		margin: '0 auto 30px !important',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh',
		[theme.breakpoints.down('md')]: {
			padding: '2vh 1.25rem 3vh',
		},
	},
	textArenaInterativo: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridTitleArenaBlock: {
		padding: '0.75rem 2rem',
		border: 'solid 4px #09D7D7',
		height: 'fit-content',
		borderRadius: 20,
		backgroundImage: `url(${require('../assets/bg-arena-cards.png')})`,
		backgroundSize: 'contain',
		boxShadow: '0px 0px 8px rgba(9, 215, 215, 1)',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem 1rem',
		},
	},
	arenaBlockTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: '1.5 !important',
			fontSize: '1.75rem !important',
		},
	},
	gridArenaBlock: {
		paddingBottom: '10rem',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '0',
		},
	},
	gridBackgroundImage: {
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('md')]: {
			height: 210,
		},
	},
	containerWaitList: {
		width: '440px !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: -40,
		left: -220,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
			width: '350px !important',
			left: -180,
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
