import React, { useRef, useEffect, useState } from 'react';
import {
	useTheme,
	useMediaQuery,
	Container,
	Grid,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	TextField,
	Typography,
} from '@mui/material';
import { FirstSectionSprintR1 } from './components/FirstSectionSprintR1';
import Api from '../../../../Services/Api';

import { Header } from '../../../../Components/Header';
import BoxTitle from './components/BoxTitle';
import StudentFeedbackItens from './components/StudentFeedbackItens';
import { cardsData, studentsComments, resumeSprint } from './components/MockSprintR1';
import BoxSlider from './components/BoxSlider';
import Footer from '../../../../Components/Footer';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';

//assets
import logoCG from './assets/logo-cg.svg';
import logoCM from './assets/logo-sprint-final-r3-cm-black.svg';
import CursoImg from './assets/HeaderCurso.png';

//slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FourthSectionSprintR1 } from './components/FourthSectionSprintR1';
import StudentsComments from './components/StudentsComments';
import BoxResumeSprint from './components/BoxResumeSprint';

//styles
import { makeStyles } from '@mui/styles';
import PaymentCardContainer from './components/PaymentCardContainer';
import AnimatedNumber from './components/AnimatedNumber';
import BoxText from './components/Boxtext';
import { useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import CountDown from './components/CounterDown';
import PaymentContainer from '../componentes/PaymentContainer';

export const SprintFinalR12024 = () => {
	const theme = useTheme();
	const classes = useStyles();
	const carousel = useRef(null);
	const [feedbacks, setFeedbacks] = useState([]);

	const [open, setOpen] = useState(false);
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'SPRINT FINAL R3 CM 2024',
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const paymentSectionRef = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
		const fetchFeedbacks = async () => {
			try {
				const response = await Api.get('depoimentos?tipo=sprint');
				const fetchedFeedbacks = response.filter((item) => {
					return item.dep_texto !== '';
				});
				setFeedbacks(fetchedFeedbacks);
			} catch (error) {
				console.error('Erro ao buscar feedbacks:', error);
			}
		};

		fetchFeedbacks();
	}, []);

	// useEffect(() => {
	// 	console.log('carambolas', open);
	// }, [open]);

	const scrollToPayment = () => {
		if (paymentSectionRef.current) {
			paymentSectionRef.current.scrollIntoView({
				behavior: 'smooth',
				block: matchesTablet ? 'start' : 'center',
			});
		}
	};

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 7500,
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const settingsComments = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 770,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 523,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 7500,
					pauseOnHover: true,
					arrow: false,
					centerMode: true,
				},
			},
		],
	};

	const settingsCardHover = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrow: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		centerPadding: '100px',
	};

	useEffect(() => {
		const fetchFeedbacks = async () => {
			try {
				const response = await Api.get('depoimentos?tipo=sprint');
				const fetchedFeedbacks = response.filter((item) => {
					return item.dep_texto !== '';
				});
				setFeedbacks(fetchedFeedbacks);
			} catch (error) {
				console.error('Erro ao buscar feedbacks:', error);
			}
		};

		fetchFeedbacks();
	}, []);

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 700;
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							// confirmationMessage
							// 	? confirmationMessage
							// 	:
							'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							document.location.reload();
						}
					});
				}
			})
		);
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: '#F5F5F5',
			}}
		>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={classes.popup}
				maxWidth="xl"
				PaperProps={{
					style: {
						borderRadius: 20,
						// backgroundColor: 'pink',
					},
				}}
			>
				<DialogContent className={classes.dialogContent}>
					<form
						className="form-control"
						action="javascript:;"
					>
						<Grid
							item
							container
							justifyContent={'center'}
							className={classes.containerWaitList}
							rowGap={1}
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
							>
								<Typography
									color={'#303030'}
									fontFamily={'Manrope'}
									className={`${classes.titlePopup}`}
								>
									Inscreva-se para ser avisado de novidades
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={matchesTablet ? { width: '100%' } : { width: '97%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-end'}
							>
								<TextField
									fullWidth
									type="tel"
									name="telefone"
									placeholder="Seu celular com DDD"
									required={true}
									pattern="[0-9]*"
									inputProps={{
										maxLength: 15,
									}}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={matchesTablet ? { width: '100%' } : { width: '97%' }}
									onChange={(e) => handlePhone(e)}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={matchesMobile ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
				<DialogActions style={{ alignSelf: 'center', padding: '1rem 4rem 2rem' }}>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.btnWaitList} btn`}
						onClick={() => sendPreInscricao()}
						autoFocus
					>
						&nbsp; Confirmar inscrição
					</DefaultButton>
				</DialogActions>
			</Dialog>
			{matchesMobile ? null : matchesTablet ? <Header whitePattern /> : null}
			{matchesMobile || matchesTablet ? null : <Header whitePattern />}
			<Container
				maxWidth={false}
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'center'}
					>
						<FirstSectionSprintR1 scrollToPayment={scrollToPayment} />
						<Grid
							container
							justifyContent="start"
							alignItems="center"
							className="section"
							style={{
								height: matchesMobile && 800,
							}}
						>
							<Grid
								item
								justifyContent={'flex-start'}
							>
								<img src={CursoImg} />
							</Grid>

							<BoxTitle title={'Sprint Final é muito mais.'}>
								<Slider
									{...settingsComments}
									style={{ width: '100%' }}
								>
									{studentsComments.map((index) => (
										<Grid
											key={index}
											style={{ width: matchesMobile ? 300 : 400 }}
										>
											<StudentsComments
												background={matchesMobile ? index.backgroundMobile : index.background}
												text={index.textComments}
												name={index.name}
												course={index.course}
												type={'residencia'}
											/>
										</Grid>
									))}
								</Slider>
							</BoxTitle>
						</Grid>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className="section"
						>
							<BoxTitle
								title={
									<>
										+<AnimatedNumber number={14673} /> histórias Hardwork construídas
									</>
								}
							>
								<Grid
									container
									justifyContent={'space-around'}
									alignItems={'center'}
									spacing={0}
									xs={12}
								>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent="center"
										alignItems="center"
									>
										<BoxText />
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent="center"
										alignItems="center"
									>
										<Slider
											{...settings}
											style={{
												width: matchesMobile ? '100%' : '70%',
												padding: matchesMobile ? '0' : '12px 0px',
											}}
										>
											{cardsData.map((card, index) => (
												<Grid
													key={index}
													className="slick-slide"
												>
													<BoxSlider
														title={card.title}
														subtitle={card.subtitle}
														text={card.text}
														background={
															matchesTablet ? card.backgroundMobile : card.background
														}
													/>
												</Grid>
											))}
										</Slider>
									</Grid>
								</Grid>
							</BoxTitle>
						</Grid>
					</Grid>
				</Container>
				<Container
					maxWidth={false}
					disableGutters={true}
					style={{
						backgroundColor: '#EBEBEB',
					}}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
						style={{ display: 'flex' }}
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className="section"
							style={{
								paddingBottom: '2vh',
							}}
						>
							<BoxTitle title={'O que você terá no Sprint?'}>
								<Slider
									{...settingsCardHover}
									style={{ width: '100%' }}
								>
									{resumeSprint.map((index) => (
										<BoxResumeSprint
											key={index}
											icon={index.icon}
											title={index.title}
											text={index.text}
										/>
									))}
								</Slider>
							</BoxTitle>
						</Grid>
					</Container>
				</Container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					style={{ display: 'flex', flexDirection: 'column' }}
				>
					<Grid
						container
						className="section"
						ref={paymentSectionRef}
					>
						<BoxTitle
							title={'Qual opção é a melhor para você?'}
							percent
						>
							<PaymentContainer />
							{/* <PaymentCardContainer />   */}
						</BoxTitle>
					</Grid>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						className="section"
					>
						<BoxTitle title={'Vai fazer prova de especialidades clínicas ou cirúrgicas?'}>
							<Grid
								item
								container
								justifyContent={'space-around'}
								alignItems={'center'}
								xs={12}
								style={{ marginTop: '1rem' }}
							>
								<Grid
									item
									xs={6}
									container
									flexDirection={'column'}
									justifyContent={'center'}
									alignItems={'center'}
									className={classes.imageHover}
									rowGap={2}
								>
									<img
										width={480}
										src={logoCM}
										alt="r3CM"
									/>
									<Button
										variant="outlined"
										// href="https://home.hardworkmedicina.com.br/pre-inscricao/trilha-r3-cg"
										className={`${classes.buttonHover} poppins`}
										onClick={() => handleClickOpen()}
									>
										Em breve
									</Button>
								</Grid>
								<Grid
									item
									xs={6}
									container
									flexDirection={'column'}
									justifyContent={'center'}
									alignItems={'center'}
									className={classes.imageHover}
									rowGap={2}
								>
									<img
										width={480}
										src={logoCG}
										alt="r3CG"
									/>
									<Button
										variant="outlined"
										// href="https://home.hardworkmedicina.com.br/pre-inscricao/trilha-r3-cg"
										className={`${classes.buttonHover} poppins`}
										onClick={() => (document.location.href = ' /hardworq/cirurgia')}
									>
										Inscreva-se
									</Button>
								</Grid>
							</Grid>
						</BoxTitle>
					</Grid>
					<Grid
						container
						className="section"
					>
						<FourthSectionSprintR1 />
					</Grid>
					<Grid
						container
						className="section"
					>
						<BoxTitle title={'Depoimentos de nossos alunos'}>
							<Slider
								{...settingsCardHover}
								style={{ width: '100%' }}
							>
								{feedbacks.map((feedback, index) => (
									<Grid
										key={index}
										style={{ width: 400 }}
									>
										<StudentFeedbackItens
											description={feedback.dep_texto}
											studentName={feedback.nome}
										/>
									</Grid>
								))}
							</Slider>
						</BoxTitle>
					</Grid>
				</Container>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : (
						<Footer
							noMarginBottom
							whitePattern
						/>
					)}
				</Container>
			</Container>
			{matchesTablet ? (
				<Footer
					noMarginBottom
					whitePattern
				/>
			) : null}
			<CountDown scrollToPayment={scrollToPayment} />
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	TitleSection: {
		[theme.breakpoints.down('md')]: {
			fontSize: '25px !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px !important',
			paddingleft: '10px !important',
		},
	},
	slider: {
		'& .slick-slider': {
			display: 'flex',
			padding: '2rem !important',
			columnGap: '0rem',
		},
		'& .slick-list': {
			overflow: 'hidden !important',
		},
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		minWidth: '100vw',
		[theme.breakpoints.down(738)]: {
			position: 'relative',
		},
		[theme.breakpoints.down('sm')]: {
			height: 400,
			overflowX: 'auto',
			overflowY: 'hidden',
			paddingLeft: '7%',
		},
	},
	imageHover: {
		padding: '1rem 0',
		borderRadius: '16px',
		'&:hover': {
			boxShadow:
				'503px 503px 199px 0px rgba(0, 0, 0, 0.00), 322px 322px 182px 0px rgba(0, 0, 0, 0.01), 181px 181px 154px 0px rgba(0, 0, 0, 0.05), 80px 80px 114px 0px rgba(0, 0, 0, 0.09), 20px 20px 63px 0px rgba(0, 0, 0, 0.10)',
			transition: 'box-shadow 0.3s ease-in-out',
		},
	},
	buttonHover: {
		margin: '1rem 0 !important',
		cursor: 'pointer !important',
		borderRadius: '8px !important',
		padding: ' 0 2rem !important',
		width: 'fit-content',
		fontSize: '1rem !important',
		border: '1px solid #000000 !important',
		'&:hover': {
			backgroundColor: '#000 !important',
			color: '#fff !important',
			borderColor: '#000 !important',
			transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	containerWaitList: {
		width: '100% !important',
		borderRadius: 15,
		padding: '2rem 4rem',
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
		},
	},
	dialogContent: {
		padding: '0px !important',
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		// fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		width: 'fit-content',
		margin: '0 auto !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				transition: '0.5s ease-in-out',
				backgroundColor: '#00543b !important',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	// gridCardsData: {
	// 	'& .slick-slide slick-active slick-current': {
	// 		margin: '0 1rem !important',
	// 	},
	// 	'& .slick-slide': {
	// 		margin: '0 1rem !important',
	// 	},
	// 	'& .slick-list': {
	// 		margin: '0 -10px !important',
	// 	},
	// },
}));
