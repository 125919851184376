import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import DefaultButton from '../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import Api from '../../../Services/Api';

//Assets
import bgBlack from './Assets/bg-black.png'

const BlurContainer = ({ matchesMobile, handleSubscription, setUserEmail }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			container
			alignContent="center"
			justifyContent="center"
			className={classes.glassContainer}
		>
			<Paper
				className={`${classes.paper} rounded-card`}
				elevation={4}
			>
				<Grid
					container
					rowGap={2}
					style={{ backgroundColor: '#EBEBEB', borderRadius: 24, padding: '2.5rem 2rem' }}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							color="primary"
							align="center"
							fontFamily={'Manrope'}
							style={{
								fontWeight: '700',
								fontSize: matchesMobile ? '1.75rem' : '2rem',
								lineHeight: '38.4px',
							}}
						>
							Identifique-se com o seu e-mail de inscrição
						</Typography>
						<Typography
							color="primary"
							align="center"
							fontFamily={'Manrope'}
							style={{ fontSize: matchesMobile ? '1rem' : '1.5rem', lineHeight: '28.8px' }}
						>
							Utilize seu e-mail de inscrição para assistir a live e ter acesso aos materiais gratuitos!
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							type="email"
							name="email"
							placeholder="E-mail utilizado na sua inscrição"
							required={true}
							InputProps={{
								className: `${classes.form}`,
								style: {
									// COMO ESTILIZAR TEXTFIELD
									color: '#959595',
									fontSize: '1.25rem',
								},
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) => setUserEmail(e.target.value)}
						/>
					</Grid>
					<Grid
						container
						justifyContent={'center'}
						alignContent={'center'}
						direction={'column'}
						rowGap={1}
						className="residencia"
						style={{ marginTop: matchesMobile ? null : '1rem' }}
					>
						<DefaultButton
							variant="contained"
							color="secondary"
							className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
							style={{ width: matchesMobile ? '100%' : '45%' }}
							onClick={() => handleSubscription()}
						>
							Entrar
						</DefaultButton>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export default function HandsOn2024() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [liveIsOpen, setLiveIsOpen] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	// const [exam, setExam] = useState('');
	// const [id, setId] = useState(0);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		console.log(userEmail);
	}, [userEmail]);

	const handleSubscription = () => {
		if (userEmail) {
			Api.get(`preInscricao/${encodeURIComponent('HANDS ON')}/${encodeURIComponent(userEmail)}/`).then((res) => {
				if (res > 0) {
					setLiveIsOpen(true);
				} else {
					alert('Insira o e-mail cadastrado para acessar a live.');
				}
			});
		} else {
			alert('Insira o e-mail cadastrado para acessar a live.');
		}
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			style={{
				backgroundColor: '#000',
			}}
		>
			<Helmet>
				<title>Hands On</title>
				<meta
					name="description"
					content="Nada supera o Hardwork. Nós vamos contigo em todas as etapas do Revalida INEP."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header blackPattern /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header blackPattern/>}
			</Container>
			<Grid
           
				container
                style={{
                    
                    backgroundImage:  `url(${bgBlack})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat',
                }}
				
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
                  
                    
				>
					{matchesTablet && (
						<Grid
							item
							xs={12}
							container
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundImage: `url(${require('./Assets/bg-hands-on-pocus.png')})`,
									backgroundSize: 'cover',
									height: '55vh',
									paddingTop: 80,
								}}
							>
								<img
									src={require('./Assets/logo-hands-on.svg').default}
									alt=""
								/>
							</Grid>
						</Grid>
					)}
					<Grid
						item
						container
						alignItems={'center'}
						className="section"
						wrap={matchesTablet ? 'nowrap' : 'wrap'}
						direction={matchesTablet ? 'column-reverse' : 'row'}
						rowGap={matchesTablet ? 4 : 0}
						style={{
							margin: '0 auto',
							height: !matchesTablet && '100vh',
							// padding: matchesTablet ? '80px 5% 0' : '3rem 0',
							padding: !matchesTablet && '3rem 0',
						}}
					>
						<Grid
							item
							xs={12}
							md={5}
							container
							rowGap={matchesTablet ? 2 : 2.5}
							style={{ height: 'fit-content' }}
						>
							<Typography
								fontFamily={'Manrope'}
								color={'secondary'}
								fontWeight={800}
								className={classes.firstTitle}
							>
								Hardwork Hands-On
								<br /> POCUS e Ventilação
							</Typography>
							<Typography
								fontFamily={'Manrope'}
								color={'secondary'}
								className={classes.text}
							>
								Treinamento intenso com foco em POCUS (ultrassonografia Point of Care) e suporte
								ventilatório, explorando todos os conceitos essenciais para a vida prática e para as
								provas.
							</Typography>
							<Typography
								fontFamily={'Manrope'}
								color={'secondary'}
								fontWeight={800}
								style={{ fontSize: '1.5rem', lineHeight: '32.78px' }}
							>
								28 SET • A partir das 8H00 <br /> Evento online e gratuito
							</Typography>
						</Grid>
						{!matchesTablet && (
							<Grid
								item
								xs={12}
								md={7}
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundImage: `url(${require('./Assets/bg-hands-on-pocus.png')})`,
									backgroundSize: 'cover',
									height: '100%',
									borderRadius: 24,
								}}
							>
								<img
									src={require('./Assets/logo-hands-on.svg').default}
									alt=""
								/>
							</Grid>
						)}
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					container
				>
					{!liveIsOpen && (
						<BlurContainer
							matchesMobile={matchesMobile}
							handleSubscription={handleSubscription}
							setUserEmail={setUserEmail}
						/>
					)}
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							container
							className="section"
							style={matchesTablet ? { padding: '6rem 0 0' } : { margin: '60px auto' }}
						>
							<Grid
								item
								xs={12}
								container
								direction={'column'}
								style={{ marginBottom: '1.5rem', padding: matchesTablet && '0 1rem' }}
							>
								<Typography
									className={`${classes.subtitle1}`}
									fontFamily={'Manrope'}
									color="secondary"
								>
									Assista a live do Hardwork Hands-On
								</Typography>
								<Typography
									className={`${classes.subtitle2}`}
									fontFamily={'Manrope'}
									color="secondary"
								>
									Acompanhe ao vivo um treinamento intenso de habilidades clínicas na prática!
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								rowGap={3}
								style={{
									backgroundColor: '#1d1d1',
									padding: matchesTablet ? '2rem 1rem' : '2.5rem',
									borderRadius: matchesTablet ? 0 : 32,
								}}
							>
								<iframe
									width="100%"
									height={matchesTablet ? '187px' : '632px'}
									loading="lazy"
									src="https://www.youtube.com/embed/gmz5zsbRkww?si=pqy9WI3-yrY0JucF"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerpolicy="strict-origin-when-cross-origin"
									allowfullscreen
									style={{ borderRadius: '20px' }}
								></iframe>
								{/* <iframe
                                        width={'1350'}
                                        height={'562.5'}
                                        loading="lazy"
                                        src={lives[idCurrentLive]?.url_video}
                                        title="Live stream video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
                                        style={{ borderRadius: '20px' }}
                                    ></iframe> */}
								<Grid
									item
									xs={12}
									container
									wrap={'nowrap'}
									justifyContent={'space-between'}
									columnGap={1.25}
									rowGap={2}
									style={{
										overflowX: matchesTablet ? 'auto' : null,
									}}
								>
									{Array.from(Array(5)).map((_, index) => (
										<Grid
											key={index}
											item
											xs={4}
											md={2.4}
											container
											direction={'column'}
											rowGap={1}
											style={{
												borderRadius: 16,
												backgroundColor: '#1D1D1D',
												padding: matchesTablet ? '0.5rem' : '1rem',
												minWidth: matchesTablet && '31%',
											}}
										>
											<Typography
												color="secondary"
												className="poppins"
												style={{
													fontSize: matchesTablet ? '1rem' : '1.5rem',
													fontWeight: 'bold',
													margin: matchesTablet ? '0' : '0.5rem 0',
												}}
											>
												Vídeo 01
											</Typography>
											<Grid
												item
												container
												columnGap={matchesTablet ? 0.5 : 1}
												style={{
													width: matchesTablet ? '100%' : '85%',
													backgroundColor: '#EBEBEB',
													borderRadius: 8,
													padding: matchesTablet ? '0.2rem 0.5rem' : '0.25rem 0.75rem',
												}}
											>
												<img
													src={require('./Assets/icon-circle-play.svg').default}
													alt=""
													style={{ width: matchesTablet ? 10 : 'unset' }}
												/>
												<Typography
													color="#101010"
													className="poppins"
													style={{
														fontWeight: 'bold',
														fontSize: matchesTablet ? '0.625rem' : '0.875rem',
														marginRight: !matchesTablet && 8,
													}}
												>
													10h20 - 14h50
												</Typography>
											</Grid>
										</Grid>
									))}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								style={{
									backgroundColor: '#1D1D1D',
									borderRadius: matchesTablet ? 0 : 24,
									paddingRight: matchesTablet ? 0 : '2rem',
									marginTop: '2rem',
								}}
							>
								<Grid
									item
									xs={12}
									md={7}
									container
									rowGap={3}
									className="residencia"
									style={{ padding: matchesTablet ? '3.5rem 0 3.5rem 1rem' : '4.5rem 2rem 3.5rem' }}
								>
									<Grid
										item
										xs={12}
										container
									>
										<Grid
											item
											xs={8}
											md={12}
											container
											rowGap={matchesTablet ? 2 : 0}
										>
											<Typography
												color="secondary"
												fontFamily={'Manrope'}
												style={{
													fontSize: '2rem',
													fontWeight: 'bold',
													lineHeight: 'unset',
												}}
											>
												Faça o download do material gratuito
											</Typography>
											<Typography
												color="secondary"
												fontFamily={'Manrope'}
												style={{
													fontSize: matchesTablet ? '1.25rem' : '1.5rem',
													lineHeight: matchesTablet && '24px',
												}}
											>
												A estratégia perfeita para entrar na alma da banca
											</Typography>
										</Grid>
										{matchesTablet && (
											<Grid
												item
												xs={4}
												container
												style={{
													backgroundImage: `url(${require('./Assets/img-book-hands-on-mobile.png')})`,
													backgroundSize: 'cover',
												}}
											></Grid>
										)}
									</Grid>
									<DefaultButton
										variant="contained"
										color="secondary"
										className={`${classes.downloadBtn} btn-secondary-invert-gradient poppins`}
										style={{
											marginRight: matchesTablet && '1rem',
										}}
										// onClick={showDetails}
									>
										<img
											src={require('./Assets/icon-book-and-pencil.png')}
											alt=""
										/>
										Baixe o material gratuito
									</DefaultButton>
								</Grid>
								{!matchesTablet && (
									<Grid
										item
										xs={12}
										md={5}
										container
										style={{
											backgroundImage: `url(${require('./Assets/img-book-hands-on.png')})`,
											backgroundSize: 'cover',
										}}
									></Grid>
								)}
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						className="section"
						style={{
							margin: '6rem auto 60px',
							backgroundColor: matchesTablet ? '#040404' : 'unset',
							padding: matchesTablet ? '2vh 5% 3rem' : '2vh 5% 0',
						}}
					>
						<Typography
							color={'secondary'}
							fontFamily={'Manrope'}
							style={{
								fontWeight: 'bold',
								fontSize: matchesTablet ? '2rem' : '2.75rem',
								lineHeight: matchesTablet && 1.25,
							}}
						>
							Conheça outros produtos Hardwork
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent={'space-between'}
							rowGap={2}
							style={{ marginTop: matchesTablet ? '1.5rem' : '2.5rem' }}
						>
							<Grid
								item
								xs={12}
								md={5.5}
								container
								direction={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								rowGap={3.5}
								className={classes.gridProductsBtn}
							>
								<img
									src={require('./Assets/logo-sf-2024.svg').default}
									alt=""
								/>
								<DefaultButton
									variant={'outlined'}
									color={matchesTablet ? 'secondary' : 'secondary'}
									className={`${classes.productsBtn} btn poppins`}
									component={Link}
									to="/sprint-final"
								>
									Acessar
								</DefaultButton>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.5}
								container
								direction={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								rowGap={3.5}
								className={classes.gridProductsBtn}
							>
								<img
									src={require('./Assets/logo-trilha-r1-2024.svg').default}
									alt=""
								/>
								<DefaultButton
									variant={'outlined'}
									color={matchesTablet ? 'secondary' : 'secondary'}
									className={`${classes.productsBtn} btn poppins`}
								>
									Em breve
								</DefaultButton>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{matchesTablet ? null : (
				<Footer
					noMarginBottom
					
				/>
			)}
			{matchesTablet ? (
				<Footer
					noMarginBottom
					
				/>
			) : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	firstTitle: {
		fontSize: '2.5rem !important',
		lineHeight: '54.64px !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
			lineHeight: 'unset !important',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '32.78px !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
			lineHeight: '27.32px !important',
		},
	},
	paper: {
		borderRadius: '21px !important',
		padding: '3.75rem 2.5rem',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '3.75rem 1rem',
			borderRadius: '0px !important',
		},
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		// boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
			lineHeight: '27.32px !important',
		},
	},
	downloadBtn: {
		fontSize: '1.5rem !important',
		width: '80%',
		padding: '0.25rem 2rem !important',
		color: '#fff !important',
		columnGap: '16px',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
			padding: '0.5rem 2rem !important',
			width: '100%',
		},
	},
	productsBtn: {
		padding: '0 3rem !important',
		border: '1.5px solid #fff !important',
		width: 'fit-content',
		fontSize: '1.125rem !important',
		fontWeight: '500 !important',
		[theme.breakpoints.down('md')]: {
			backgroundColor: '#000000 !important',
		},
	},
	gridProductsBtn: {
		backgroundColor: '#1d1d1d',
		padding: '2rem 3rem 1rem',
		borderRadius: 16,
	},
	form: {
		'& .MuiInputBase-root MuiOutlinedInput-root': {
			fontSize: '1.25rem !important',
		},
	},
}));
