import { useEffect, useState } from 'react';
import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Api from '../../../../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { useStylesPurchaseCard } from './PurchaseCard';
import moment from 'moment/moment';

const groups = [
	{
		id: 1,
		active: false,
		day: ['04'],
		month: 'Maio',
	},
	{
		id: 2,
		active: false,
		day: ['18'],
		month: 'Maio',
	},
	{
		id: 3,
		active: false,
		day: ['19'],
		month: 'Maio',
	},
];

export default function PurchaseCardOnline20242({ activeButtonId, idTurma, urlToRedirect, confirmationMessage, ref }) {
	const classes = useStylesPurchaseCard();
	const specificClasses = useStyles();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [turmaSelecionada, setTurmaSelecionada] = useState(null);
	const [currentLot, setCurrentLot] = useState([]);
	const [isOpenCard, setIsOpenCard] = useState(false);
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `ARENA 2024.2 ONLINE LISTA DE ESPERA`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) {
				setTurma(res[0]);

				const data_hoje = moment(new Date()).format();

				const current_lot = res[0]?.lotes?.filter(
					(lot) =>
						data_hoje > moment(lot?.data_inicio).format() && data_hoje < moment(lot?.data_limite).format()
				);

				if (current_lot?.length > 0) {
					setCurrentLot(current_lot);
					setIsOpenCard(true);
				} else {
					setIsOpenCard(false);
				}
			}
		});
	}, [idTurma]);

	useEffect(() => {
		if (turma && turma.id) {
			setPreInscricao({
				agrupamento: `ARENA 2024.2 ONLINE - LISTA DE ESPERA`,
				nome: '',
				email: '',
				telefone: '',
				utm_source: '',
				utm_medium: '',
				utm_campaign: '',
			});
		}
	}, [turma]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Avisaremos assim que novas turmas estiverem liberadas.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
		setTurmaSelecionada(null);
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	return (
		<Grid
			container
			style={!activeButtonId ? { opacity: 0.65 } : null}
			className={`${specificClasses.root}`}
		>
			<Grid
				item
				xs={12}
				container
			>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent={'center'}
					className={specificClasses.background}
					style={{ padding: activeButtonId === '1374' && matchesTablet ? '2em 2em 1em' : '3rem 3rem 1rem' }}
				>
					<img
						src={require('../assets/logo-arena-online-2024.svg').default}
						alt="logo"
						style={{ width: matchesLg ? '60%' : matchesTablet ? '70%' : 'unset' }}
					/>
				</Grid>
				<Grid
					item
					container
					direction={'column'}
					justifyContent={activeButtonId === 1 ? 'space-evenly' : 'space-around'}
					alignContent={'center'}
					className={classes.containerOptions}
				>
					<Grid
						item
						container
						direction={'column'}
						className="revalida"
					>
						{!isOpenCard ? (
							<>
								<Typography
									color={'secondary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									{idTurma === '1374' ? 'Abertura em 17/09' : 'NOVAS TURMAS EM BREVE!'}
								</Typography>
							</>
						) : (
							<>
								<Typography
									color="secondary"
									className={`${specificClasses.paymentModeText} poppins`}
									style={{ marginTop: '1rem' }}
								>
									À&nbsp;
									<span
										className="gradient-text"
										style={{ display: 'inline', fontWeight: 700 }}
									>
										vista
									</span>
									&nbsp;por
								</Typography>
								<Typography
									className={`poppins`}
									fontSize={matchesMobile ? 15 : 30}
									fontWeight={700}
									style={{ textDecoration: 'line-through' }}
								>
									R${' '}
									{turma?.valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.priceText} roboto-condensed`}
								>
									R${' '}
									{currentLot[0]?.valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
								</Typography>
								<Typography
									color="secondary"
									className={`${specificClasses.paymentModeText} poppins`}
									style={{ marginTop: 25 }}
								>
									Ou até&nbsp;
									<span
										className="gradient-text"
										style={{ display: 'inline', fontWeight: 700 }}
									>
										{turma?.max_parcelas}x
									</span>
									&nbsp;de
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.priceText} roboto-condensed`}
								>
									R${' '}
									{turma?.parcelas && turma.parcelas?.length > 0
										? `${turma?.parcelas[turma.parcelas?.length - 1].valor?.toLocaleString(
												'pt-BR',
												{
													minimumFractionDigits: 2,
												}
										  )}`
										: ''}
								</Typography>
							</>
						)}
					</Grid>

					<Grid
						item
						container
						alignContent={'center'}
						justifyContent={'center'}
						marginTop={10}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${specificClasses.btn} btn`}
							component={Link}
							onClick={!isOpenCard ? () => handleOpenWaitList() : () => {}}
							to={isOpenCard ? `//hardworkmedicina.com.br/inscricao/${1374}` : null}
						>
							{!isOpenCard ? 'Avise-me' : 'Inscreva-se'}
						</DefaultButton>
					</Grid>

					<form
						className="form-control"
						action="javascript:;"
					>
						<Grid
							item
							container
							justifyContent={'center'}
							className={classes.containerWaitList}
							rowGap={1}
							style={{ visibility: waitListVisibility }}
						>
							<Grid
								item
								container
								justifyContent="space-between"
							>
								<Typography
									color="primary"
									className={`${classes.textWaitList} poppins`}
								>
									Inscrever-se na Lista de Espera
								</Typography>
								<CancelRoundedIcon
									onClick={() => handleCloseWaitList()}
									className={classes.cancelIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={matchesTablet ? { width: '100%' } : { width: '97%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justifyContent={matchesTablet ? 'center' : 'flex-end'}
							>
								<TextField
									fullWidth
									type="tel"
									name="telefone"
									placeholder="Seu celular com DDD"
									required={true}
									pattern="[0-9]*"
									inputProps={{
										maxLength: 15,
									}}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={matchesTablet ? { width: '100%' } : { width: '97%' }}
									onChange={(e) => handlePhone(e)}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={matchesMobile ? 'center' : 'flex-start'}
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={10}
								lg={8}
								container
								className="residencia"
								style={{ height: 50, padding: '4px 0px' }}
							>
								<DefaultButton
									// type="submit"
									variant="outlined"
									color="secondary"
									className={`${classes.btnWaitList} btn`}
									onClick={() => sendPreInscricao()}
								>
									<img
										src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
										alt=""
										style={{ width: 20 }}
									/>{' '}
									&nbsp; Confirmar inscrição
								</DefaultButton>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 15,
		backgroundColor: '#000000',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			border: 'unset',
		},
	},
	discountText: {
		fontSize: '0.85rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
	},
	gridDiscount: {
		borderTop: 'solid 1px #515151',
		paddingTop: '2rem !important',
		marginTop: '2rem !important',
		[theme.breakpoints.down('md')]: {
			paddingTop: '1rem !important',
			marginTop: '1rem !important',
		},
	},
	background: {
		padding: '3rem',
		width: '55% !important',
		backgroundColor: '#000000',
		borderTopLeftRadius: '15px !important',
		borderBottomLeftRadius: '15px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '2em',
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
			borderBottomLeftRadius: '0 !important',
			borderTopRightRadius: '15px !important',
			padding: '2em 2em 1em',
		},
	},
	turmaText: {
		fontWeight: '500 !important',
		bottom: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '0.7rem !important',
		},
	},
	turmaBox: {
		borderRadius: 9,
		padding: '0.5rem 1rem',
		border: 'solid 1px #09D785',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem',
		},
	},
	spanText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		top: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	spanText2: {
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	gridContainerTurmas: {
		borderTop: 'solid 1px #515151',
		marginTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			borderTop: 'solid 1px #515151',
			marginTop: '2rem !important',
		},
	},
	btn: {
		width: '90%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '75%',
		},
	},
	supportText: {
		fontSize: '0.9rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	paymentModeText: {
		fontSize: '1.5rem !important',
		lineHeight: 'unset !important',
	},
}));
