import React, { useRef, useState } from 'react';
import { Container, useTheme, useMediaQuery, Grid, Typography } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';

//Components
//import NotebookSlider from '../componentes/NotebookSlider';
//import Form from '../../../../Components/Utils/Forms/PreRegistration';
import { FirstSectionSale } from '../componentes/FirstSectionSale';
import BoxResumeSprintV2 from '../SprintFinal/components/BoxResumeSprintV2.jsx';
import PaymentCard from '../componentes/PaymentCard';
import { Header } from '../../../../Components/Header';
import { cardsData, cardsTeacher, resume, temasEnare, temasUsp } from './components/mockR3CM.js';
import Footer from '../../../../Components/Footer';
import BoxTitle from '../SprintFinal/components/BoxTitle';
import CounterDown from '../componentes/CounterDown';
import { ListOfThemesContainer } from '../componentes/ListOfThemesContainer.jsx';

//Assets
import logo from '../R3/assets/logo-hwq-cm.svg';
import imgMobile from '../R3/assets/img-hwq-cm-mobile.png';
import logoUsp from '../R3/assets/logo-cm-usp.svg';
import logoEnare from '../R3/assets/logo-cm-enare.svg';
import logoUspEnare from '../R3/assets/logo-cm-usp-enare.svg';
import bgTechao from '../R3/assets/bg-ao.png';
import bgRedLineMobile from '../R3/assets/bg-red-lines-mobile.png';
import bgBlur from '../R3/assets/bg-blur-orange.png';

const R3ClinicaMedica = () => {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));
	const [isOpenThemeList, setIsOpenThemeList] = useState(false);

	const paymentSectionRef = useRef(null);

	const scrollToPayment = () => {
		if (paymentSectionRef.current) {
			paymentSectionRef.current.scrollIntoView({
				behavior: 'smooth',
				block: matchesTablet ? 'start' : 'center',
			});
		}
	};

	const handleOpenThemesList = () => {
		setIsOpenThemeList(true);
	};

	return (
		<div style={{ backgroundColor: '#020202', minHeight: '100vh' }}>
			<Container
				maxWidth={false}
				disableGutters={true}
				style={{
					// minHeight: '100vh',
					backgroundColor: '#040404',
				}}
			>
				{matchesMobile ? null : matchesTablet ? <Header blackPattern /> : null}
				{matchesMobile || matchesTablet ? null : <Header blackPattern />}
				<div
					style={{
						backgroundImage: matchesMobile ? `url(${bgRedLineMobile})` : `url(${bgTechao})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center', // centraliza a imagem
						backgroundRepeat: 'no-repeat',
					}}
				>
					<Container
						maxWidth={false}
						disableGutters={true}
					>
						<Container
							fixed
							maxWidth="xl"
							disableGutters={true}
						>
							<Grid
								container
								justifyContent={'center'}
								className="section"
								style={{
									padding: matchesTablet ? null : '5rem 0 3rem',
									margin: matchesTablet ? '60px auto' : '0 auto 5rem',
								}}
							>
								<FirstSectionSale
									color
									firstParagraph={[
										'A revisão de reta final com foco intenso nas mensagens peculiares das bancas de especialidades clínicas da USP e do ENARE!',
									]}
									secondParagraph={
										<span>
											<b>Tu escolhes o que quer dominar no R3: USP, ENARE ou ambas!</b>
										</span>
									}
									logoImg={logo}
									imageApp={imgMobile}
									scrollToPayment={scrollToPayment}
									colorBorderButton={'#FD2C7898'}
									colorBgButton={'linear-gradient(304deg, #FD2C78, #FF8251)'}
									colorTextButton={'linear-gradient(304deg, #FD2C78, #FF8251)'}
									colorStartButton={'#FD2C78'}
									colorEndButton={'#FF8251'}
								/>
							</Grid>
						</Container>
						<Grid
							container
							justifyContent={'center'}
							alignContent={'center'}
						>
							<BoxTitle
								themeColor={'dark'}
								color
								title={'O que é o Sprint Final R3 de Clínica Médica?'}
							>
								<Grid
									item
									className="section"
									style={{ borderRadius: '10px' }}
								>
									<Splide
										options={{
											arrows: false,
											autoplay: true,
											type: 'loop',
											speed: 0.25,
											rewind: true,
										}}
									>
										{cardsData.map((item, index) => {
											return (
												<SplideSlide
													key={index}
													style={{
														display: 'flex',
														justifyContent: matchesMobile ? 'end' : 'end',
													}}
												>
													<img
														src={matchesMobile ? item?.bgMobile : item?.bg}
														alt=""
														style={{
															width: '100%',

															borderRadius: matchesMobile ? '0' : '36px',
														}}
													/>
													<Grid
														style={{
															position: 'absolute',
															top: matchesMobile ? 16 : 0,
															left: matchesMobile ? 40 : 100,
															width: matchesMobile ? '80%' : '50%',
															height: '100%',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'start',
															color: 'white',
															textAlign: 'end',
															padding: matchesMobile ? '0' : '35px',
														}}
													>
														<Typography
															fontFamily={'Manrope'}
															style={{
																marginBottom: '10px',
																fontWeight: 'bold',
																textAlign: 'start',
																fontSize: matchesMobile ? '21px' : '30px',
															}}
														>
															{item.title}
														</Typography>
														<Typography
															fontFamily={'Manrope'}
															variant="body1"
															style={{
																textAlign: 'start',
																fontSize: matchesMobile ? '16px' : '24px',
															}}
														>
															{item.text}
														</Typography>
													</Grid>
												</SplideSlide>
											);
										})}
									</Splide>
								</Grid>
							</BoxTitle>
						</Grid>
					</Container>
					<Grid
						className="section"
						container
						justifyContent={'center'}
						alignContent={'center'}
						// paddingLeft={matchesMobile ? 0 : 5}
						marginBottom={10}
					>
						{cardsTeacher.map((item, index) => {
							return (
								<Grid
									className="section"
									container
									style={{
										display: 'flex',
										justifyContent: matchesMobile ? 'center' : 'end',
									}}
								>
									<img
										src={matchesMobile ? item?.bgMobile : item?.bg}
										alt=""
										style={{
											width: '100%',

											borderRadius: matchesMobile ? '0' : '36px',
										}}
									/>
									<Grid
										style={{
											position: 'absolute',
											top: matchesMobile ? 16 : 0,
											left: matchesMobile ? 40 : 100,
											width: matchesMobile ? '80%' : '50%',
											height: '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'start',
											color: 'white',
											textAlign: 'start',
											padding: matchesMobile ? '0' : '35px',
										}}
									>
										<Typography
											fontFamily={'Manrope'}
											style={{
												marginBottom: matchesMobile ? '100px' : '10px',
												fontWeight: 'bold',
												textAlign: 'start',
												fontSize: matchesMobile ? '21px' : '30px',
											}}
										>
											{item.title}
										</Typography>
										<Typography
											fontFamily={'Manrope'}
											variant="body1"
											style={{
												textAlign: 'start',
												fontSize: matchesMobile ? '16px' : '20px',
											}}
										>
											{item.text}
										</Typography>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
					<Container
						maxWidth={false}
						disableGutters={true}
					>
						<Container
							fixed
							maxWidth="xl"
							disableGutters={true}
							style={{ display: 'flex' }}
						>
							<Grid
								container
								justifyContent="start"
								alignItems="start"
								className={!matchesTablet ? 'section' : null}
							>
								<BoxTitle
									themeColor={'dark'}
									color
									title={'O que você terá no Sprint Final R3 de Clínica Médica?'}
								>
									<Grid
										container
										justifyContent={'center'}
										rowGap={3}
										columnGap={3}
										style={{
											backgroundSize: '100% 100%',
											backgroundPosition: 'end',
										}}
									>
										{resume.map((item, index) => (
											<Grid
												key={index}
												item
												xs={12}
												lg={3.5}
												xl={3.85}
												container
												style={{ height: !matchesTablet && 324 }}
											>
												<BoxResumeSprintV2
													color
													icon={item.icon}
													title={item.title}
													text={item.text}
													buttonFunction={index === 0 ? () => handleOpenThemesList() : null}
												/>
											</Grid>
										))}
									</Grid>
									{isOpenThemeList && (
										<Grid
											item
											xs={12}
											container
											style={{
												position: 'absolute',
												backgroundColor: '#303030',
												height: matchesTablet ? 'fit-content' : '100%',
												borderRadius: 12,
												padding: matchesTablet ? '1rem 1rem 2rem' : '1.5rem',
											}}
										>
											<Grid
												item
												xs={12}
												container
												style={{
													padding: matchesTablet ? 0 : '0.75rem',
													display: matchesTablet && 'inline',
												}}
											>
												<Grid
													item
													xs={12}
													container
													style={{ height: 'fit-content', marginBottom: '1.5rem' }}
												>
													<Typography
														color={'secondary'}
														fontFamily={'Manrope'}
														style={{ fontSize: matchesTablet ? '1.2rem' : '1.25rem' }}
													>
														Confira a lista de todos os temas
													</Typography>
													<Grid
														onClick={() => setIsOpenThemeList(false)}
														style={{
															position: 'absolute',
															right: matchesTablet ? '-0.5rem' : '-1.5rem',
															top: matchesTablet ? '-0.5rem' : '-1.5rem',
															cursor: 'pointer',
														}}
													>
														<img
															src={require('./assets/icon-close-grey.png')}
															alt=""
														/>
													</Grid>
												</Grid>
												<Grid
													item
													xs={12}
													container
													justifyContent={'space-between'}
												>
													<ListOfThemesContainer
														institutionName={'ENARE'}
														temas={temasEnare}
														matchesTablet={matchesTablet}
														matchesDownXl={matchesDownXl}
													/>
													<ListOfThemesContainer
														institutionName={'USP-SP'}
														temas={temasUsp}
														matchesTablet={matchesTablet}
														matchesDownXl={matchesDownXl}
													/>
												</Grid>
											</Grid>
										</Grid>
									)}
								</BoxTitle>
							</Grid>
						</Container>
					</Container>
					<CounterDown
						date={'2024-08-09T23:59:00-03:00'}
						text={'Domine as duas provas pelo preço de uma!'}
						textMobile={'Domine as duas provas pelo preço de uma!'}
						scrollToPayment={scrollToPayment}
						colorEndButton={'#FF8251'}
						colorStartButton={'#FD2C78'}
					/>
				</div>
				<Grid
					item
					container
					ref={paymentSectionRef}
					style={{
						backgroundColor: '#1D1D1D',
						backgroundImage: `url(${bgBlur})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						padding: matchesDownXl ? '0rem 0' : '5rem 0',
                        
					}}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							// className="section"
                            paddingTop={matchesDownXl ? 5 : 0}
						>
							<BoxTitle
								themeColor={'dark'}
								color
								title={'Qual prova de R3 de Clínica Médica quer dominar?'}
							>
								<Grid
									item
									container
									justifyContent={'space-between'}
									alignItems={'center'}
									flexDirection={matchesMobile ? 'column' : 'row'}
									flexWrap={'wrap'}
									columnGap={matchesMobile ? 5 : 3}
									padding={0}
									style={{ height: matchesTablet && 'fit-content' }}
                                    paddingBottom={matchesDownXl ? 10 : 0}
								>
									{/* <NotebookSlider /> */}
									<Grid
										item
										xs={12}
										lg={3.7}
										container
										style={{ height: '100%' }}
									>
										<PaymentCard
											product={'Sprint R3 CM'}
                                            productColor={"gradient-text-sprint-default"}
											start
											discount
											color
											logo={logoUsp}
											idTurma={'1371'}
											institutionName={'USP'}
										/>
									</Grid>
									<Grid
										product={'Sprint R3 CM'}
                                        productColor={"gradient-text-sprint-default"}
										item
										xs={12}
										lg={3.7}
										container
										style={{ height: '100%' }}
									>
										<PaymentCard
											product={'Sprint R3 CM'}
                                            productColor={"gradient-text-sprint-default"}
											start
											discount
											color
											logo={logoEnare}
											idTurma={'1373'}
											institutionName={'ENARE'}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										lg={3.7}
										container
										style={{ height: '100%' }}
									>
										<PaymentCard
											product={'Sprint R3 CM'}
                                            productColor={"gradient-text-sprint-default"}
											start
											discount
											color
											logo={logoUspEnare}
											price={2990}
											priceParcel={2093}
											shadow={'0px 0px 20px 0px rgba(255, 111, 92, 0.60)'}
											idTurma={'1373 , 1371'}
											bestPrice={true}
											colorBgButton={'linear-gradient(304deg, #FD2C78, #FF8251)'}
											institutionName={'USP + ENARE'}
										/>
									</Grid>
									{/* <Grid
                                item
                                xs
                            >
                                <Form
                                    group={'HARDWORQ R3 CIRURGIA GERAL 2024'}
                                    title={'Inscreva-se para ser avisado!'}
                                    titleButton={'Confirmar inscrição'}
                                    selectContest
                                    type={'sprint-gray'}
                                    urlToRedirect={'https://home.hardworkmedicina.com.br/hardworq/cirurgia'}
                                    confirmationMessage={'Sua pré-inscrição está confirmada!'}
                                />
                            </Grid> */}
								</Grid>
							</BoxTitle>
						</Grid>
					</Container>
				</Grid>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						{matchesTablet ? null : <Footer noMarginBottom />}
					</Container>
				</Container>
				{matchesTablet ? <Footer noMarginBottom /> : null}
			</Container>
		</div>
	);
};

export default R3ClinicaMedica;
