import React, { useState } from 'react';
import { Grid, Typography, Button, Tooltip, ClickAwayListener, useMediaQuery, useTheme } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { makeStyles } from '@mui/styles';

const BoxTitle = ({ children, themeColor, title, enableScroll, percent, color }) => {
	const theme = useTheme();
	const styles = useStyles();
	const classes = useStyles();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<Grid
			item
			container
			rowGap={matchesTablet ? 2 : 5}
			className="section"
		>
			<Grid
				item
				container
				alignItems={'center'}
				justifyContent={'space-between'}
				wrap="nowrap"
				className={classes.container}
			>
				<Typography
					color={themeColor === 'dark' ? '#FCFCFC' : '#101010'}
					fontFamily={'Manrope'}
					fontWeight={'bold'}
					className={classes.title}
				>
					{title}
				</Typography>
				{percent && (
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<div>
							<Tooltip
								TransitionComponent={Zoom}
								title="*Válido para alunos Trilha 2023 ou Sprint Final 2023"
								open={open}
								onClose={handleTooltipClose}
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<Button
									className={`${classes.optionButton} ${open ? classes.optionButtonHover : ''}`}
									onClick={handleTooltipOpen}
									onMouseEnter={handleTooltipOpen}
									onMouseLeave={handleTooltipClose}
									style={{
										background: themeColor === 'dark' ? '#656565' : '#EBEBEB',
										color: themeColor === 'dark' ? '#FCFCFC' : '#000',
									}}
								>
									<span style={{ whiteSpace: 'nowrap' }}>15% de desconto para ex-alunos*</span>
								</Button>
							</Tooltip>
						</div>
					</ClickAwayListener>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				container
				flexWrap={'nowrap'}
				columnGap={4}
				className={`${enableScroll ? styles.gridCarouselContainer : ''}`}
			>
				{children}
			</Grid>
		</Grid>
	);
};

export default BoxTitle;

const useStyles = makeStyles((theme) => ({
	gridCarouselContainer: {
		overflowX: 'scroll',
		scrollBehavior: 'smooth',
		[theme.breakpoints.down('sm')]: {
			overflowX: 'auto',
		},
	},
	optionButton: {
		cursor: 'pointer',
		borderRadius: '24px !important',
		padding: '0 1rem !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		fontWeight: '600 !important',
		alignSelf: 'flex-end',
		// marginBottom: '0.5rem !important',
		//transition: 'width 0.3s ease-in-out, color 0.3s ease-in-out',
	},
	title: {
		fontSize: '3rem !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			fontSize: '1.75rem !important',
		},
	},
	container: {
		display: 'flex !important',
		rowGap: 16,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column !important',
		},
	},
}));
