import ProductsTemplate from '../../../Pages/LandingPages/Subscription/Residencia/Products-Template-2024';
import ProductsTemplateCidade from '../../../Pages/LandingPages/Subscription/Residencia/Product-Template-CIdade';

import HandsOn from '../TemplatesContent/Hands-on';
import HandsOnCity from '../TemplatesContent/Hands-on-city';

import JN from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro-norte.png';
import JU from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro.png';
import AL from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-alagoinhas.png';
import TE from '../../../Pages/LandingPages/Subscription/Residencia/assets/logo-teresina.png';

import SprintFinalR3CM from '../TemplatesContent/Sprint-final-r3-cm';
import LiveRmaisCg from '../TemplatesContent/Live-rmais-cg';

// Arquivo que configura as páginas de pré inscrição

const routesProductsTemplate = [
	{
		path: '/pre-inscricao/hardwork-hands-on', // Cria a url
		component: ProductsTemplate, // Usa o componente template como base
		props: {
			group: 'Hands On', // Define o agrupamento do formulário
			children: <HandsOn />, // Aqui passamos a parte dinâmica de cada página, ou seja, o conteúdo à esquerda
			formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-hands-on.png'), // Logo acima do formulário
			backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/pocus-ultrassom-hands-on.png'), // Background
		},
	},
	// {
	// 	path: '/hardwork-juazeiro-do-norte',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-JUAZEIRO-DO-NORTE',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={JN}
	// 				cidade={'Juazeiro do Norte'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro-norte.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-juazeiro',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-JUAZEIRO',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={JU}
	// 				cidade={'Juazeiro'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-juazeiro.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-alagoinhas',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-ALAGOINHAS',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={AL}
	// 				cidade={'Alagoinhas'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-alagoinhas.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	// {
	// 	path: '/hardwork-teresina',
	// 	component: ProductsTemplateCidade,
	// 	props: {
	// 		group: 'EVENTO-TERESINA',
	// 		children: (
	// 			<HandsOnCity
	// 				logo={TE}
	// 				cidade={'Teresina'}
	// 			/>
	// 		),
	// 		formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-teresina.png'),
	// 		backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/new-bg-cidades.png'),
	// 	},
	// },
	{
		path: '/pre-inscricao/sprint-r3-clinica-medica',
		component: ProductsTemplate,
		props: {
			group: 'Sprint R3 Clínica Médica 2024',
			children: <SprintFinalR3CM />,
			formLogo: require('../Assets/logo-sf-cm-2024.svg').default,
			backgroundImg: require('../Assets/bg-sprint-cm-2024.png'),
		},
	},
	{
		path: '/pre-inscricao/live-imagens-cirurgia',
		component: ProductsTemplate,
		props: {
			group: 'Live R+ Cirurgia Imagens',
			children: <LiveRmaisCg />,
			formLogo: require('../Assets/logo-rmais-cg.svg').default,
			backgroundImg: require('../Assets/bg-rmais-cg.png'),
		},
	},
	// Adicione mais rotas conforme necessário
];

export default routesProductsTemplate;
