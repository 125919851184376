import React, { useState, useEffect } from 'react';
import { Checkbox, Grid, Paper, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
// import { validateCPF } from '../validateCpf';

export default function Form({
	closeGroup,
	group,
	title,
	subtitle,
	titleButton,
	color,
	type,
	confirmationMessage,
	titleConfirmationMessage,
	noCellphone,
	textColor,
	liveCorrecaoOptions,
}) {
	const classes = useStyles();
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group,
		nome: '',
		email: '',
		telefone: '',
		rg: '',
		provas: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [typeSubmit, setTypeSubmit] = useState(false);

	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	useEffect(() => {
		setPreInscricao((prevState) => ({
			...prevState,
			agrupamento: closeGroup ? `${group} LISTA DE ESPERA` : group,
		}));
	}, [closeGroup, group]);

	const isArena = window.location.pathname.includes('react-revalida');

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone && !noCellphone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		// if (!preInscricao.optin) {
		// 	alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
		// 	return;
		// }
		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					localStorage.setItem('email-correcao', preInscricao.email);

					Swal.fire({
						title: `${titleConfirmationMessage ? titleConfirmationMessage : 'Massa!'} `,
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							localStorage.setItem('email-correcao', preInscricao.email);
							window.location.reload();
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	return (
		<Paper
			elevation={4}
			className={`${isArena ? classes.paperArena : classes.paper} rounded-card`}
			style={{ backgroundColor: color }}
		>
			<Grid container>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							justifyContent="center"
							className={classes.gridTitle}
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<Typography
								className={`${liveCorrecaoOptions ? classes.titleCorrecaoOptions : classes.title}`}
								color={textColor}
								align="center"
								fontFamily={'Manrope'}
							>
								{title}
							</Typography>
							{subtitle && (
								<Typography // Só para a Correção Revalida -- Tirar depois de passar a correção
									className={`${classes.subtitle}`}
									color={textColor}
									align="center"
									fontFamily={'Manrope'}
								>
									{subtitle}
								</Typography>
							)}
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								inputProps={{
									sx: {
										'&::placeholder': {
											color: 'black',
										},
									},
								}}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						{noCellphone ? null : (
							<Grid
								item
								xs={12}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="telefone"
									type="telefone"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
						)}
						{!liveCorrecaoOptions && (
							<Grid
								item
								xs={12}
							>
								<Checkbox
									style={{
										padding: '0px',
										// color: textColor === 'primary' ? '#ABABAB' : '#ffffff',
									}}
									checked={preInscricao.optin}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											optin: !preInscricao.optin,
										})
									}
								/>
								<Typography
									variant="caption"
									color={textColor}
									className={classes.captionText}
									fontFamily={'Manrope'}
								>
									Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
									plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos
									serviços educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
									<Link
										href="https://hardworkmedicina.com.br/politica-de-privacidade"
										target="_blank"
										underline="hover"
										variant="caption"
										align="right"
									>
										Politica de privacidade
									</Link>
								</Typography>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							style={{ marginTop: '1.5rem' }}
							className={liveCorrecaoOptions ? 'revalida' : null}
						>
							<DefaultButton
								type={typeSubmit ? 'submit' : 'button'}
								variant="outlined"
								color={'secondary'}
								className={`${classes.btn}`}
								onClick={(e) => sendPreInscricao(e)}
								backgroundColor="#000000 "
								style={
									liveCorrecaoOptions
										? {
												width: matchesTablet ? '80%' : '40%',
												fontWeight: 'bold',
												fontSize: '1.5rem',
										  }
										: { fontSize: '1rem', fontWeight: '400' }
								}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '2rem 1.5rem',
		[theme.breakpoints.down('sm')]: {
			padding: '2.5rem 1.5rem',
		},
	},
	paperArena: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.down('lg')]: {
			width: '294px !important',
			top: '-68px !important',
			left: 38,
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
			top: 'unset !important',
			left: 'unset !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	title: {
		fontSize: '1.625rem !important',
		fontWeight: '500 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	titleCorrecaoOptions: {
		fontSize: '2rem !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',

		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	btn: {
		width: '100%',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},

		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color:
			window.location.pathname === '/materiais/semana-tep' ||
			window.location.pathname === '/pre-inscricao/sprint-final-2024'
				? theme.palette.primary.main
				: theme.palette.secondary.main,
	},
	captionText: {
		fontSize: '0.65rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.6rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectMenuUf: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectFieldText: {
		fontSize: '0.9rem !important',
	},
	foodRequirementText: {
		fontSize: '1.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridTitle: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '1.25rem !important',
	},
	placeholder: {
		color: '#000000 !important',
	},
}));
