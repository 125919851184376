import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import transmissionLive from '../../../Pages/LandingPages/Subscription/Residencia/assets/live-transmission.png';

const InfoBox = ({ title, subtitle, img }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			container
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Grid
				item
				xs={3}
				md={2}
			>
				<img
					src={img}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={8.5}
				md={9.5}
				container
				style={{ height: 'fit-content' }}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.titleBox}`}
					fontFamily={'Manrope'}
				>
					{title}
				</Typography>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.boxText}`}
					fontFamily={'Manrope'}
				>
					{subtitle}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default function HandsOn({ cidade, logo }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={2}
		>
			{matchesTablet && (
				<img
					src={logo}
					alt=""
					style={{ width: matchesMobile && '80%', margin: matchesTablet && '0 auto' }}
				/>
			)}
			{cidade === 'Alagoinhas' ? (
				<Typography
					align="left"
					color="secondary"
					className={`${classes.title}`}
					fontFamily={'Manrope'}
				>
					O Hardwork vai te visitar!
				</Typography>
			) : (
				<Typography
					align="left"
					color="secondary"
					className={`${classes.title}`}
					fontFamily={'Manrope'}
				>
					O Hardwork vai te visitar
					<br />
					em {cidade}!
				</Typography>
			)}

			{cidade === 'Alagoinhas' ? (
				<Typography
					align="left"
					color="secondary"
					className={`${classes.text}`}
					fontFamily={'Manrope'}
				>
					O time que mais entende de preparação com foco em bancas de residência fará um evento PRESENCIAL e
					GRATUITO, para discutirmos dezenas de temas importantíssimos da medicina!
				</Typography>
			) : (
				<Typography
					align="left"
					color="secondary"
					className={`${classes.text}`}
					fontFamily={'Manrope'}
				>
					O time que mais entende de preparação com foco em bancas de residência fará um evento PRESENCIAL e
					GRATUITO em {cidade}, para discutirmos dezenas de temas importantíssimos da medicina!
				</Typography>
			)}

			<Typography
				align="left"
				color="secondary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				A ideia é revisar diversos temas com foco em uma grande prova de residência médica, e quem escolherá és
				tu!
			</Typography>
			<Typography
				align="left"
				color="secondary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				Em breve divulgaremos local e data, e te avisaremos das inscrições (o evento será gratuito, mas as vagas
				limitadas).*
			</Typography>

			<Grid style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					Data <span style={{ color: '#656565' }}>a confirmar </span>
				</Typography>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					Local <span style={{ color: '#656565' }}>a confirmar </span>
				</Typography>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.impactText}`}
					fontFamily={'Manrope'}
				>
					Evento presencial e gratuito
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '3.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontSize: '1.2rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	impactText: {
		fontWeight: '800 !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	titleBox: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	boxText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
	},
}));
