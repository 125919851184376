import { useTheme, useMediaQuery, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import Api from '../../../../Services/Api';
import BagIcon from '../../../../Assets/Svg/Icons/bagIcon.svg';

const PaymentCard = ({
	idTurma,
	logo,
	priceParcel,
	priceParcel2,
	price: propPrice,
	bestPrice,
	shadow,
	colorBgButton,
	color,
	discount,
	start,
	institutionName,
    product,
    productColor
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const [apiPrice, setApiPrice] = useState(null);
	const [apiPriceParcel, setApiPriceParcel] = useState(null);
	const [apiPriceParcel2, setApiPriceParcel2] = useState(null);
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [startDate, setStartDate] = useState(null);

	useEffect(() => {
		if (!propPrice || !priceParcel) {
			Api.get(`turmasabertas/${idTurma}`).then((res) => {
				if (res && res.length > 0) {
					const turma = res[0];
					if (turma) {
						setStartDate(new Date(turma?.data_inicio_inscricoes));
					}
					if (!propPrice) setApiPrice(turma?.valor);
					if (!priceParcel && turma?.parcelas.length > 0) {
						setApiPriceParcel(turma?.parcelas[0].valor);
					}
				}
			});
		}
	}, [idTurma, propPrice, priceParcel]);

	const price = propPrice || apiPrice;
	const parcelPrice = priceParcel || apiPriceParcel;
	const parcelPrice2 = priceParcel || apiPriceParcel ? (priceParcel || apiPriceParcel) / 12 : 0;

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(value);
	};

	// const isRegistrationOpen = startDate && startDate <= new Date();
	const isRegistrationOpen = true;

	return (
		<Grid
			container
			justifyContent={'end'}
			alignItems={'center'}
			marginTop={matchesMobile ? '2rem' : '0'}
			style={{ background: color ? '#101010' : '#EBEBEB', borderRadius: '24px', boxShadow: shadow }}
		>
			{bestPrice && (
				<Button
					disabled
					className={classes.optionButton}
					style={{
						background: colorBgButton,
					}}
				>
					Melhor opção
				</Button>
			)}
			<Grid
				item
				container
				xl={12}
				lg={12}
				justifyContent={'center'}
				alignItems={'center'}
				direction="column"
				style={{ textAlign: discount ? 'start' : 'center', padding: '4rem 2rem 3rem' }}
			>
				<Grid
					item
					container
					direction={'column'}
					className="residencia"
					style={{ marginBottom: '1.5rem' }}
				>
					<Typography
						style={{ fontSize: '1.5rem', fontWeight: 500, lineHeight: 'unset' }}
						fontFamily={'Manrope'}
						className={productColor}
					>
						{product}
					</Typography>
					<Typography
						style={{ fontSize: '3rem', fontWeight: 'bold', lineHeight: 'unset' }}
						fontFamily={'Manrope'}
						className={productColor}
					>
						{institutionName}
					</Typography>
				</Grid>
				<Grid
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="start"
						gap={'2px'}
					>
						<Typography
							fontFamily={'Manrope'}
							fontSize={'16px'}
							fontWeight={'500'}
							color={'#959595'}
							style={{ textDecoration: 'line-through' }}
						>
							De: {formatCurrency(price)}
						</Typography>
						<Typography
							fontFamily={'Manrope'}
							fontSize={'16px'}
							color={color ? '#FCFCFC' : '#000'}
						>
							<span style={{ fontWeight: 600, fontSize: 18 }}>Por: {formatCurrency(parcelPrice)}</span>{' '}
							{color  && <span style={{ color: '#EBEBEB' }}>à vista com</span>} {''}
							{color  && <span style={{ color: '#FCFCFC', fontWeight: 600, fontSize: 18 }}>30% Off ou</span>} 
						</Typography>
					</Grid>
					<Typography
						fontFamily={'Manrope'}
						fontSize={matchesMobile ? '30px' : '37px'}
						fontWeight={'700'}
						justifyContent="flex-start"
						alignItems="flex-start"
						color={color ? '#fff' : '#000'}
						style={{
							lineHeight: '1.25',
						}}
					>
						{'12x sem juros de'} {formatCurrency(parcelPrice2)}
					</Typography>
				</Grid>
				<Button
					onClick={() =>
						isRegistrationOpen
							? (window.location.href = `https://hardworkmedicina.com.br/inscricao/${idTurma}`)
							: console.log()
					}
					// href={`https://hardworkmedicina.com.br/inscricao/${idTurma}`}
					startIcon={
						<img
							src={''}
							alt=""
						/>
					}
					className={`${classes.subscribeButton}`}
					style={{ padding: '0.25rem 3rem', background: '#33C96F', color: '#fff' }}
				>
					<img
						src={BagIcon}
						alt=""
						style={{ width: '1.2rem', marginRight: '0.5rem' }}
					/>
					<Typography
						color={'secondary'}
						fontFamily={'Manrope'}
						style={{
							fontSize: '1.31rem',
							fontWeight: 'bold',
						}}
					>
						{isRegistrationOpen ? 'Inscrever-se' : 'Em breve'}
					</Typography>
				</Button>
			</Grid>
		</Grid>
	);
};

export default PaymentCard;

const useStyles = makeStyles((theme) => ({
	subscribeButton: {
		borderRadius: '24px !important',
		width: '100%',
		marginTop: '2rem !important',
		textTransform: 'unset !important',
	},
	optionButton: {
		position: 'absolute !important',
		top: '0.5rem',
		cursor: 'pointer',
		borderRadius: '24px !important',
		marginRight: '1rem !important',
		marginTop: '0.5rem !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#fff !important',
		alignSelf: 'flex-end',
	},
}));
